import React, { memo } from "react";

import { ICON_SIZE_TO_SIZE_VALUE_MAP } from "../constants";
import { CreateIconComponentOptions, IconProps } from "../types";

export const createIconComponent = ({
  displayName,
  icon: SvgIcon,
  size,
  viewBox,
}: CreateIconComponentOptions) => {
  const IconComponent = (props: IconProps) => {
    const { size: sizeInProps, ...restProps } = props;
    const newSize = sizeInProps ?? size;
    const newSizeValue = newSize
      ? ICON_SIZE_TO_SIZE_VALUE_MAP[newSize]
      : undefined;
    const sizeProps = newSize
      ? { width: newSizeValue, height: newSizeValue }
      : {};

    return <SvgIcon {...sizeProps} {...restProps} viewBox={viewBox} />;
  };

  IconComponent.displayName = displayName;

  return memo(IconComponent);
};
