/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import Loader24Svg from "!!@svgr/webpack!./assets/loader-24.svg";

export const Loader24Icon = createIconComponent({
  displayName: "Loader24Icon",
  icon: Loader24Svg as any,
  size: IconSize.Md,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 24 24",
});
