/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import Search16Svg from "!!@svgr/webpack!./assets/search-16.svg";

export const Search16Icon = createIconComponent({
  displayName: "Search16Icon",
  icon: Search16Svg as any,
  size: IconSize.Sm,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 16 16",
});
