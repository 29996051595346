/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import Attention16Svg from "!!@svgr/webpack!./assets/attention-16.svg";

export const Attention16Icon = createIconComponent({
  displayName: "Attention16Icon",
  icon: Attention16Svg as any,
  size: IconSize.Sm,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 16 16",
});
