/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import ChevronDownSquare16Svg from "!!@svgr/webpack!./assets/chevron-down-square-16.svg";

// Figma - dropdown 16
export const ChevronDownSquare16Icon = createIconComponent({
  displayName: "ChevronDownSquare16Icon",
  icon: ChevronDownSquare16Svg as any,
  size: IconSize.Sm,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 16 16",
});
