/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import Edit16Svg from "!!@svgr/webpack!./assets/edit-16.svg";

export const Edit16Icon = createIconComponent({
  displayName: "Edit16Icon",
  icon: Edit16Svg as any,
  size: IconSize.Sm,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 24 25",
});
