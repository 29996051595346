/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import Star32Svg from "!!@svgr/webpack!./assets/star-32.svg";

export const Star32Icon = createIconComponent({
  displayName: "Star32Icon",
  icon: Star32Svg as any,
  size: IconSize.Lg,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 32 32",
});
