/* eslint-disable import/no-unresolved */
import { IconSize } from "./constants";
import { createIconComponent } from "./utils";

import ArrowDown16Svg from "!!@svgr/webpack!./assets/arrow-down-16.svg";

export const ArrowDown16Icon = createIconComponent({
  displayName: "ArrowDown16Icon",
  icon: ArrowDown16Svg as any,
  size: IconSize.Sm,
  // TODO: remove from here when all icons will be created with createIconComponent and we will be able to set up svgr/webpack
  // Now svgr/webpack removes viewBox from svgs
  viewBox: "0 0 16 16",
});
