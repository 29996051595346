export enum IconSize {
  /** 16 */
  Sm = "sm",
  /** 24 */
  Md = "md",
  /** 32 */
  Lg = "lg",
  /** 48 */
  Xl = "xl",
}

export const ICON_SIZE_TO_SIZE_VALUE_MAP = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
};
